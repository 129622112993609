$(document).ready(function () {

    /* Mobile nav */
    $(".nav-handler").click(function () {
        var $this = $(this);

        $this.toggleClass("open");
        $("header nav").slideToggle(250);
        $("body").toggleClass("scroll-disabled");
    });


    if ($(".branch.location").length) {
        let positionPromise = new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(function (position) {
                console.dir(position);
                resolve(position);
            }, function (error) {
                console.dir(error);
                reject(error);
            }, {timeout: 10000, enableHighAccuracy: true});
        });

        positionPromise.then(position => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            let fd = new FormData();
            fd.append("lat", lat);
            fd.append("lng", lng);

            $.ajax({
                url: "/api/posts/",
                method: "POST",
                data: fd,
                processData: false,
                contentType: false,
            }).done((data) => {
                $(".branch.location").html(data);
                $(".branch-location-wrapper").show();
            });
        });
    }

    /*
 * Replace all SVG images with inline SVG
 */
    jQuery("img.svg").each(function () {
        var $this = $(this);
        const imgID = $this.attr("id");
        const imgClass = $this.attr("class");
        const imgURL = $this.attr("src");

        $.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find("svg");

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== "undefined") {
                $svg = $svg.attr("id", imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== "undefined") {
                $svg = $svg.attr("class", imgClass + " replaced-svg");
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr("xmlns:a");

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if (!$svg.attr("viewBox") && $svg.attr("height") && $svg.attr("width")) {
                $svg.attr("viewBox", "0 0 " + $svg.attr("height") + " " + $svg.attr("width"));
            }

            // Replace image with new SVG
            $this.replaceWith($svg);

        }, "xml");

    });


    /* INPUT remove placeholder */
    $("input:not(.head-search), textarea").each(function () {
        let $this = $(this);
        const input_placeholder = $this.attr("placeholder");

        $this.on("focus", () => {
            $this.attr("placeholder", "");
        });

        $this.on("blur", () => {
            $this.attr("placeholder", input_placeholder);
        });
    });

    if ($(".page.page--create-sipo").length) {

        $(".step-head h3").matchHeight();
        $(".step-head div").matchHeight();

        $(".step-head button").on("click", function () {
            var content = $(this).attr("data-content");
            $(".row.head").addClass("active");

            $(".step-head button").removeClass("active");
            $(this).addClass("active");

            if ($(".step-content").is(":visible")) {
                $(".step-content").hide();
                $(".step-content." + content).fadeIn(1000);
            } else {
                $(".step-content").hide();
                $(".step-content." + content).slideToggle(1000);
            }

            $([document.documentElement, document.body]).animate({
                scrollTop: $(".step-content." + content).offset().top
            }, 1000);

        });

    }


    $(".faq-item h3").on("click", function (e) {
        e.preventDefault();
        $(this).parent().toggleClass("open").find(".faq-item__detail").slideToggle(200);
    });

    $(".main-search").select2({
        minimumInputLength: 3,
        language: "cs",
        ajax: {
            url: "/api/search/",
            dataType: "json"
        },
        width: "100%"
    });

    $(document).on("select2:selecting", ".main-search", function (e) {
        window.location.href = e.params.args.data.url;
    });

    $("form.find-company").on("submit", function (e) {
        e.preventDefault();
        e.stopPropagation();

        const newUrl = removeURLParameter(window.location.href, "firma");
        window.history.pushState("", "", newUrl);

        var url = $(this).attr("action");
        var data = $(this).serialize();
        $.ajax({url: url, data: data}).done(function (data) {
            $(".availability-results").html(data).show();
        });

    });

    function removeURLParameter(url, parameter) {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split("?");
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + "=";
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0;) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
        }
        return url;
    }

    if ($(".page.page--search").length) {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get("q");
        const company = urlParams.get("firma");

        if (company != null) {
            const url = `/api/companies-search/${company}/`;
            $.ajax(url).done(function (data) {
                $(".availability-results").html(data).show();
            });
            if(myParam != null) {
                const $form = $("form.find-company");
                $form.find("input").val(myParam);
            }
        } else if (myParam != null) {
            const $form = $("form.find-company");
            $form.find("input").val(myParam);
            $form.submit();
        }
    }

    if ($(".page.page--faq").length) {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get("otazka");

        if (myParam != null) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(`#otazka${myParam}`).offset().top - 45
            }, 250);
            $(`#otazka${myParam}`).addClass("open").find(".faq-item__detail").show();
        }
    }

    $("body").on("click", ".featured-company", function () {
        const url = $(this).data("url");
        const $form = $("form.find-company");
        $form.find("input").val("");
        $.ajax(url).done(function (data) {
            $(".found-companies").html(data);
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".availability-box").offset().top
            }, 250);
        });
    });
});

$(window).on("load", function()
{
    setTimeout(function()
    {
        $("header form").css({
            opacity: 1,
        });
    }, 800);
});
