import "../styles/desktop.scss";

import "@fancyapps/fancybox";
import "select2/dist/js/select2";
import "select2/dist/js/i18n/cs";
import "jquery-match-height/jquery.matchHeight";

import "./libs/polyfill";

import "./app";
